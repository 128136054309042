exports.components = {
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-strapi-post-slug-js": () => import("./../../../src/pages/blog/{strapiPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-post-slug-js" */),
  "component---src-pages-buzzermode-index-jsx": () => import("./../../../src/pages/buzzermode/index.jsx" /* webpackChunkName: "component---src-pages-buzzermode-index-jsx" */),
  "component---src-pages-choice-index-jsx": () => import("./../../../src/pages/choice/index.jsx" /* webpackChunkName: "component---src-pages-choice-index-jsx" */),
  "component---src-pages-docs-autogen-index-jsx": () => import("./../../../src/pages/docs/autogen/index.jsx" /* webpackChunkName: "component---src-pages-docs-autogen-index-jsx" */),
  "component---src-pages-docs-questionbank-index-jsx": () => import("./../../../src/pages/docs/questionbank/index.jsx" /* webpackChunkName: "component---src-pages-docs-questionbank-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-features-factile-jeopardy-index-jsx": () => import("./../../../src/pages/features/factile-jeopardy/index.jsx" /* webpackChunkName: "component---src-pages-features-factile-jeopardy-index-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-features-multiple-choice-jeopardy-index-jsx": () => import("./../../../src/pages/features/multiple-choice-jeopardy/index.jsx" /* webpackChunkName: "component---src-pages-features-multiple-choice-jeopardy-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactivechoice-index-jsx": () => import("./../../../src/pages/interactivechoice/index.jsx" /* webpackChunkName: "component---src-pages-interactivechoice-index-jsx" */),
  "component---src-pages-memory-index-jsx": () => import("./../../../src/pages/memory/index.jsx" /* webpackChunkName: "component---src-pages-memory-index-jsx" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/notFound.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-ourreviews-index-jsx": () => import("./../../../src/pages/ourreviews/index.jsx" /* webpackChunkName: "component---src-pages-ourreviews-index-jsx" */),
  "component---src-pages-plans-index-jsx": () => import("./../../../src/pages/plans/index.jsx" /* webpackChunkName: "component---src-pages-plans-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-quizbowl-index-jsx": () => import("./../../../src/pages/quizbowl/index.jsx" /* webpackChunkName: "component---src-pages-quizbowl-index-jsx" */),
  "component---src-pages-remotejoin-index-jsx": () => import("./../../../src/pages/remotejoin/index.jsx" /* webpackChunkName: "component---src-pages-remotejoin-index-jsx" */),
  "component---src-pages-remotelearning-index-jsx": () => import("./../../../src/pages/remotelearning/index.jsx" /* webpackChunkName: "component---src-pages-remotelearning-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

